import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { getAuthorization, removeToken } from '../utils/auth';
import { parseJwt } from '../utils/token';
import DatasetService from '../service/DatasetService';
import { formatNumber } from '../utils/formatNumber';
import moment from 'moment';
import UserService from '../service/UserService';

import { clean } from 'rut.js';

export const AppContext = React.createContext();

const TIME_DATASET_INTERVAL = 1000 * 20 * 1;
class AppContextProvider extends Component {
  initializeLoadDataset() {
    const interval = setInterval(() => {
      this.loadDataset();
    }, TIME_DATASET_INTERVAL);

    return interval;
  }

  loadDataset() {
    DatasetService.getAll().then(({ data }) => {
      const { result } = data;

      const actualYear = parseInt(moment().format('YYYY'));
      const actualCapacitance = result.capacitanceList.find((c) => c.year === actualYear);
      this.setState({
        capacitanceData: {
          year: actualCapacitance.year,
          totalHours: formatNumber(actualCapacitance.totalHours),
          totalParticipants: formatNumber(actualCapacitance.totalParticipants),
          totalTrained: formatNumber(actualCapacitance.totalTrained),
          actualCourses: formatNumber(actualCapacitance.actualCourses),
        },
      });
      this.setState({ isDatasetLoading: false });
    });
  }

  getSystemFunctions() {
    UserService.getSystemFunctions({ system: 'CC' })
      .then(({ data }) => {
        const { result } = data;

        const { enterprises, holdings, assignAllEnterprises, systems, enterprise } = result;
        const formatedRutEnterprises = enterprises
          .filter((e) => (clean(e.rut) ? true : false))
          .map((e) => ({ ...e, rut: Number(clean(e.rut).slice(0, -1)) }));

        const formatedEnterprise = enterprise ? Number(clean(enterprise.rut).slice(0, -1)) : null;
        this.setState((prev) => ({
          functions: systems[0].functions,
          user: {
            enterprises: formatedRutEnterprises,
            holdings,
            assignAllEnterprises,
            enterprise: formatedEnterprise,
            ...prev.user,
          },
        }));
      })
      .finally(() => {
        this.setState(() => ({ isAllDataLoaded: true }));
      });
  }

  userActions = {
    setUser: (user) => {
      this.setState({
        user,
      });

      if (user) {
        sessionStorage.setItem('otic.user', JSON.stringify(user));

        if (!this.state.interval) {
          const datasetInterval = this.initializeLoadDataset();
          this.setState({ datasetInterval, isDatasetLoading: true });
          this.loadDataset();
        }

        this.getSystemFunctions();
        // TODO: FETCH INITIAL DATA
      }
    },
    cleanUser: () => {
      this.setState({
        user: {
          name: '',
          email: '',
          username: '',
          rut: undefined,
          id: undefined,
        },
      });
      clearInterval(this.state.datasetInterval);
      this.setState({
        isTokenRefreshing: false,
        interval: null,
        datasetInterval: null,
      });
    },
  };

  constructor() {
    super();

    this.state = {
      user: {
        name: '',
        enterprises: [],
        holdings: [],
      },
      functions: [],
      capacitanceData: {
        year: 0,
        totalHours: 0,
        totalParticipants: 0,
        totalTrained: 0,
        actualCourses: 0,
      },

      datasetInterval: null,
      isAllDataLoaded: false,
      isDatasetLoading: false,
    };
  }

  componentDidMount() {
    let authUser = sessionStorage.getItem('otic.user');

    if (authUser !== null) {
      this.setState({ user: JSON.parse(authUser) });
    } else if (getAuthorization()) {
      const tokenPayload = parseJwt(cookies.get('otic.token.private'));

      const user = {
        name: tokenPayload.name,
        username: tokenPayload.preferred_username,
        email: tokenPayload.email,
        rut: tokenPayload.rut,
        id: tokenPayload.sub,
      };
      this.userActions.setUser(user);
      authUser = user;
    }

    if (authUser && getAuthorization()) {
      const datasetInterval = this.initializeLoadDataset();

      this.setState({ datasetInterval, isDatasetLoading: true });
      this.loadDataset();
      this.getSystemFunctions();
    } else {
      this.setState(() => ({ isAllDataLoaded: true }));
      removeToken();
    }
  }

  render() {
    const { children } = this.props;
    const { user, isAllDataLoaded, capacitanceData, isDatasetLoading, functions } = this.state;
    return (
      <AppContext.Provider
        value={{
          user: { ...user, actions: this.userActions },
          capacitanceData,
          isAllDataLoaded,
          isDatasetLoading,
          functions,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

AppContextProvider.propTypes = {
  history: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withRouter(AppContextProvider);
