import { Col, Row } from 'antd';

import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../app/AppContext';
import { GraduationGatIcon } from '../../../assets/icons';
import CertificationIcon from '../../../assets/icons/Certification';
import CondecorationIcon from '../../../assets/icons/Condecoration';
import LibraryAddCheckIcon from '../../../assets/icons/LibraryAddCheck';
import LocalLibraryIcon from '../../../assets/icons/LocalLibrary';
import OutlineArrowCircleLeftIcon from '../../../assets/icons/OutlineArrowCircleLeft';
import OutlineSchoolIcon from '../../../assets/icons/OutlineSchool';
import RoundAccessAlarmIcon from '../../../assets/icons/RoundAccessAlarm';
import ThreeUsersIcon from '../../../assets/icons/ThreeUsers';
import CardImageText from '../../../components/CardImageText';
import IconDot from '../../../components/IconDot';
import SummaryItem from '../../../components/SummaryItem';

const CapacitanceReports = () => {
  const { functions } = useContext(AppContext);
  const router = useHistory();

  const reportList = [
    {
      key: 'HISTORIAL_CAPACITACION',
      component: (
        <CardImageText
          title={'Capacitación'}
          buttonText="Ver reporte"
          onClick={() => {
            router.push('/reportes/capacitacion');
          }}
          imageComponent={<IconDot icon={<GraduationGatIcon />} size={32} />}
          text={
            <ul style={{ paddingLeft: '16px' }}>
              <li> Cursos impartidos </li>
              <li> Horas de formación</li>
            </ul>
          }
        />
      ),
    },
    {
      key: 'CERTIFICACION',
      component: (
        <CardImageText
          title="Certificación"
          buttonText="Ver reporte"
          imageComponent={
            <IconDot icon={<CertificationIcon />} size={32} position={{ top: -10, left: 23 }} />
          }
          onClick={() => {
            router.push('/reportes/certificacion');
          }}
          text={
            <ul style={{ paddingLeft: '16px' }}>
              <li> N° de participantes certificados</li>
              <li> Inversión en certificación</li>
            </ul>
          }
        />
      ),
    },
    {
      key: 'PARTICIPANTES_CAPACITACION',
      component: (
        <CardImageText
          title="Participantes"
          buttonText="Ver reporte"
          onClick={() => {
            router.push('/reportes/participantes');
          }}
          imageComponent={
            <IconDot icon={<ThreeUsersIcon />} size={32} position={{ top: -5, left: 12 }} />
          }
          text={
            <ul style={{ paddingLeft: '16px' }}>
              <li> Participaciones</li>
              <li> Asistencia</li>
            </ul>
          }
        />
      ),
    },
  ];
  const { capacitanceData, isDatasetLoading } = useContext(AppContext);

  return (
    <Row gutter={12} style={{ width: '100%' }}>
      <Col span={24}>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '30px' }}
        >
          <OutlineArrowCircleLeftIcon style={{ marginRight: '6px' }} />
          <Link
            to="/"
            style={{ textAlign: 'center' }}
            onClick={() => {
              window.top.postMessage('goBackHome', '*');
            }}
          >
            Volver atrás
          </Link>
        </div>
      </Col>

      <Col xs={24} lg={{ span: 9 }}>
        <h2>
          {' '}
          <strong>Cursos de Capacitación</strong>
        </h2>
        <p>
          Encuentra aquí toda la información asociada a los cursos, participantes y certificación
          realizadas en tu compañía.
        </p>

        <div className="summary">
          <h4>Resumen {capacitanceData.year}</h4>
          <Row>
            <Col span={12}>
              <SummaryItem
                value={capacitanceData.totalParticipants}
                icon={<LocalLibraryIcon />}
                description="Participaciones"
                loading={isDatasetLoading}
              />
            </Col>
            <Col span={12}>
              <SummaryItem
                value={capacitanceData.totalTrained}
                icon={<OutlineSchoolIcon />}
                description="Alumnos capacitados"
                loading={isDatasetLoading}
              />
            </Col>
            <Col span={12}>
              <SummaryItem
                value={capacitanceData.actualCourses}
                icon={<LibraryAddCheckIcon />}
                description="N° de cursos inscritos"
                loading={isDatasetLoading}
              />
            </Col>
            <Col span={12}>
              <SummaryItem
                value={capacitanceData.totalHours}
                icon={<RoundAccessAlarmIcon />}
                description="Horas de capacitación"
                loading={isDatasetLoading}
              />
            </Col>
          </Row>
        </div>
      </Col>

      <Col xs={24} lg={{ span: 15 }}>
        <Row gutter={6}>
          {functions.map((f) => {
            const report = reportList.find((r) => r.key === f.key);

            return (
              <Col xs={24} md={{ span: 12 }} lg={{ span: 8 }} style={{ marginBottom: '75px' }}>
                {report.component}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default CapacitanceReports;
