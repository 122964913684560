import { HomeOutlined } from '@ant-design/icons';
import CapacitanceReports from '../domain/Reports/CapacitanceReports';
import CapacitanceReport from '../domain/Reports/CapacitanceReports/CapacitanceReport';
import CertificationReport from '../domain/Reports/CapacitanceReports/CertificationReport';
import ParticipantsReport from '../domain/Reports/CapacitanceReports/ParticipantsReport';

const routes = [
  // PRIVATE

  {
    path: '/',
    component: CapacitanceReports,
    exact: true,
    title: 'Reportes capacitación',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },

  {
    path: '/reportes/capacitacion',
    component: CapacitanceReport,
    exact: true,
    title: 'Reportes capacitación',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },
  {
    path: '/reportes/participantes',
    component: ParticipantsReport,
    exact: true,
    title: 'Reportes participantes',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },
  {
    path: '/reportes/certificacion',
    component: CertificationReport,
    exact: true,
    title: 'Reportes Certificación',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },
];

export default routes;
