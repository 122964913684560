export default function RoundAccessAlarmSVG() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8702 15.25L12.5002 13.25V8.72003C12.5002 8.32003 12.1802 8.00003 11.7802 8.00003H11.7202C11.3202 8.00003 11.0002 8.32003 11.0002 8.72003V13.44C11.0002 13.79 11.1802 14.12 11.4902 14.3L15.1402 16.49C15.4802 16.69 15.9202 16.59 16.1202 16.25C16.3302 15.9 16.2202 15.45 15.8702 15.25ZM21.1802 5.01003L18.1002 2.45003C17.6802 2.10003 17.0502 2.15003 16.6902 2.58003C16.3402 3.00003 16.4002 3.63003 16.8202 3.99003L19.8902 6.55003C20.3102 6.90003 20.9402 6.85003 21.3002 6.42003C21.385 6.31961 21.4492 6.20345 21.489 6.07821C21.5289 5.95298 21.5437 5.82111 21.5325 5.69016C21.5214 5.5592 21.4845 5.43173 21.4241 5.31503C21.3636 5.19833 21.2808 5.09469 21.1802 5.01003ZM4.10024 6.55003L7.17024 3.99003C7.60024 3.63003 7.66024 3.00003 7.30024 2.58003C7.21733 2.47887 7.11517 2.39517 6.99967 2.33379C6.88417 2.27242 6.75763 2.23459 6.6274 2.22249C6.49717 2.2104 6.36583 2.22429 6.241 2.26335C6.11618 2.30241 6.00035 2.36586 5.90024 2.45003L2.82024 5.01003C2.7197 5.09469 2.63685 5.19833 2.5764 5.31503C2.51595 5.43173 2.4791 5.5592 2.46796 5.69016C2.45681 5.82111 2.47159 5.95298 2.51145 6.07821C2.5513 6.20345 2.61546 6.31961 2.70024 6.42003C3.05024 6.85003 3.68024 6.90003 4.10024 6.55003ZM12.0002 4.00003C9.61316 4.00016 7.3239 4.94856 5.63607 6.63657C3.94824 8.32459 3.0001 10.614 3.00024 13.001C3.00037 15.3881 3.94876 17.6774 5.63678 19.3652C7.32479 21.053 9.61416 22.0012 12.0012 22.001C13.1832 22.001 14.3536 21.7681 15.4455 21.3157C16.5375 20.8633 17.5297 20.2003 18.3654 19.3645C19.2011 18.5287 19.864 17.5364 20.3163 16.4444C20.7686 15.3524 21.0013 14.182 21.0012 13C21.0012 11.8181 20.7683 10.6477 20.3159 9.55573C19.8635 8.46376 19.2005 7.47159 18.3647 6.63586C17.5289 5.80014 16.5366 5.13722 15.4446 4.68496C14.3526 4.23271 13.1822 3.99997 12.0002 4.00003ZM12.0002 20C8.14024 20 5.00024 16.86 5.00024 13C5.00024 9.14003 8.14024 6.00003 12.0002 6.00003C15.8602 6.00003 19.0002 9.14003 19.0002 13C19.0002 16.86 15.8602 20 12.0002 20Z"
        fill="#EDA645"
      />
    </svg>
  );
}
