import request from '../../utils/request';

const getCapacitance = () => {
  return request({
    url: '/api/report/capacitance',
    method: 'POST',
    private: true,
  });
};
const getParticipants = () => {
  return request({
    url: '/api/report/participants',
    method: 'POST',
    private: true,
  });
};
const getCertification = () => {
  return request({
    url: '/api/report/certification',
    method: 'POST',
    private: true,
  });
};

const ReportService = {
  getCapacitance,
  getCertification,
  getParticipants,
};

export default ReportService;
